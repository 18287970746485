/**
 * Avoid needing to manage the types and track the IDs returned from setTimeout.
 */
export class BrowserTimeoutCtrl {
  private next: ReturnType<typeof setTimeout> | null = null;

  set(...args: Parameters<typeof setTimeout>): void {
    this.clear();
    this.next = setTimeout(...args);
  }

  clear(): void {
    if (this.next) clearTimeout(this.next);
  }
}
